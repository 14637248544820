<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
          <tr>
            <td>
              <p align="left"><strong>About the Database</strong> <br>
                <br>
                Rendering of clean transparent surfaces is well studied in computer graphics.   However, transparent objects in the real world almost always have surface contaminants,   such as dust (e.g., pollen, skin cells, fabric fibers), dirt (e.g., powder, salt, other   minerals, organic materials, soil), and lipids (e.g., fingerprints, lipstick, and other   oils). These contaminants usually will both attenuate or occlude the light that passed   through and also will scatter light into various directions, which can generate many   interesting visual effects and greatly enhance the perception of transparent objects. <br>
                            </p>
              <p align="left">Besides modeling the light transport of contamination, it is also very important to consider  spatial distribution or "texture" of the contaminant, in order to reproduce photorealistic images.   To this end, we have developed methods for measuring  the spatial patterns and scattering parameters of a contaminant  layer on a thin sheet of glass or plastic. These measurements   have been incorporated into a simple  interactive tool that uses texture synthesis to generate a  contaminant layer of any desired size, with user-selected  combinations of contaminant types. </p>
              <p align="left">We release both the <router-link to="/repository/Contaminants/texture">texture patterns</router-link> and the measured <router-link to="/repository/Contaminants/scattering">scattering parameters</router-link>. Also,  the code for the synthesis tool and the code for estimating the scattering prameter from raw images  can be downloaded <router-link to="/repository/Contaminants/source">here</router-link>.</p>
              <p align="left">The acquisition and the modeling details of our work can be referred to  the following paper:</p>
		<blockquote>
		</blockquote></td></tr></tbody></table>
  </div>

</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>